import styles from '../styles/Home.module.css'
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export default function Home() {
  return (
    <div
    >
      <SwaggerUI url="/api/doc" />
    </div>
  )
}
